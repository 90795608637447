import { INTERN_CONFIGURATOR_START } from "./routes";

/**
 * @author Louis Born <louis.born@hey-gruen.de>
 */
const redirectToStart = () => {
  window.location.href = `${INTERN_CONFIGURATOR_START}`;
};

const redirectToConfirmation = (
  firstName,
  lastName,
  email,
  dealId,
  implementationType
) => {
  const params = new URLSearchParams({
    name: `${firstName} ${lastName}`,
    email: email,
    utm_term: dealId,
    selfmade: implementationType === "selfmade",
  });

  window.location.href = `https://www.hey-gruen.de/leistungen/bestaetigung?${params.toString()}`;
};

export { redirectToStart, redirectToConfirmation };
