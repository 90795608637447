import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

/**
 * @param {Object} props - The props object containing the following properties:
 * @param {string} props.id - The unique ID for the input element.
 * @param {string} props.type - The type of input (e.g., text, number, email).
 * @param {string} props.name - The name attribute for the input element.
 * @param {string} props.label - The label text for the input field.
 * @param {string} props.placeholder - The placeholder text for the input field.
 * @param {RegExp|string} props.regValidation - The regular expression pattern for input validation.
 * @param {boolean} props.required - Whether the input is required or not.
 * @param {string} props.value - The initial value for the input field.
 * @param {string} props.defaultValue - The default value for the input field.
 * @param {Function} props.onInput - Callback function for input events.
 * @param {string} props.errorMessage - The error message to display when input is invalid.
 * @param {boolean} props.allowOnlyNumbersAndOneComma - Whether to allow only numbers and one comma.
 * @returns {JSX.Element} - The rendered HEYGNumber component.
 */
function HEYGNumber({
  id,
  type,
  required,
  name,
  label,
  placeholder,
  regValidation,
  value,
  defaultValue,
  onInput,
  errorMessage,
  allowOnlyNumbersAndOneComma,
}) {
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const classNamesInput = `w-full h-[56px] heyg-text-base hover:bg-neutral-200 rounded-[4px] border-b ${
    error ? "border-HEYG-error" : "border-gray-300"
  } bg-neutral-100 text-neutral-900 focus:outline-none focus:border-HEYG-accent placeholder-neutral-400 px-2 py-2`;

  const classNamesLabel = `ml-2 mb-[2px] heyg-text-xs font-bold ${
    error ? "text-HEYG-error" : "text-HEYG-black"
  }`;

  /**
   * Handles the input change event and performs validation.
   * If allowOnlyNumbersAndOneComma is true, it enforces numbers and one comma.
   * Otherwise, it uses the regValidation pattern.
   * @param {React.ChangeEvent<HTMLInputElement>} e - The input change event object.
   */
  const handleInputChange = (e) => {
    let newValue = e.target.value;
    let isValid = true;
    let errorMessage = "";

    if (allowOnlyNumbersAndOneComma) {
      // Remove any characters that aren't numbers or commas
      let cleaned = newValue.replace(/[^0-9,]/g, "");

      // Ensure only one comma exists
      const commaCount = (cleaned.match(/,/g) || []).length;
      if (commaCount > 1) {
        // Keep only the first comma and remove others
        const parts = cleaned.split(",");
        cleaned = parts[0] + "," + parts.slice(1).join("");
        errorMessage = "Bitte gib eine gültige Zahl ein, z.B. 5 oder 5,5";
      }

      // Check if the value was modified during cleaning
      if (newValue !== cleaned) {
        newValue = cleaned;
        isValid = false;
      }
    } else if (regValidation) {
      // Use the provided regex validation
      isValid = newValue.match(regValidation) !== null;
      if (!isValid) {
        errorMessage =
          errorMessage || "Bitte gib eine gültige Zahl ein, z.B. 5 oder 5,5";
      }
    }

    setInputValue(newValue);
    setError(!isValid);
    setErrorMsg(errorMessage);

    // Call the parent's onInput handler if provided
    if (onInput) {
      // Create a synthetic event object similar to the original
      const syntheticEvent = {
        target: {
          value: newValue,
          name,
          id,
        },
      };
      onInput(syntheticEvent);
    }
  };

  useEffect(() => {
    if (defaultValue) {
      setInputValue(defaultValue);
    }
  }, [defaultValue]);

  // Update the input value when the 'value' prop changes
  useEffect(() => {
    if (value !== undefined && value !== "") {
      setInputValue(value);
    }
  }, [value]);

  return (
    <div className="w-full relative">
      <label htmlFor={id} className={classNamesLabel}>
        {label}
        {required && <span className="text-HEYG-error"> *</span>}
      </label>
      <input
        id={id}
        type={type}
        className={classNamesInput}
        name={name}
        placeholder={placeholder}
        onChange={handleInputChange}
        required={required}
        value={inputValue}
        disabled={value !== undefined && value !== ""}
      />
      <span
        id={`error-${id}`}
        className="block heyg-text-xs font-bold text-HEYG-error absolute"
      >
        {errorMsg || (error && errorMessage)
          ? errorMsg || errorMessage
          : "\u00A0"}
      </span>
    </div>
  );
}

HEYGNumber.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  regValidation: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(RegExp),
  ]),
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  onInput: PropTypes.func,
  errorMessage: PropTypes.string,
  allowOnlyNumbersAndOneComma: PropTypes.bool,
};

HEYGNumber.defaultProps = {
  required: true,
  placeholder: "",
  regValidation: "",
  value: "",
  errorMessage: "Bitte gib eine gültige Zahl ein, z.B. 5 oder 5,5",
  allowOnlyNumbersAndOneComma: false,
};

export default HEYGNumber;
