import React, { useState } from "react";
import PropTypes from "prop-types";

import HEYGAlertDialog from "../../../../../@ui-common/HEYGAlertDialog/HEYGAlertDialog";
import HEYGChoiceBox from "../../../../../@ui-common/HEYGChoiceBox/HEYGChoiceBox";
import HEYGHelpTooltip from "../../../../../@ui-common/HEYGHelpTooltip/HEYGHelpTooltip";
import SectionM from "../../../../../@ui-common/SectionM/SectionM";
import ConfiguratorPrice from "../configurator-price/configurator-price";
import ConfiguratorSubmit from "../configurator-submit/configurator-submit";

function ConfiguratorChoices({
  processError,
  onSubmitCfg,
  steps,
  roofSize,
  implementationType,
}) {
  const initialConfig = {};
  const [state, setState] = useState({ currentStep: 0, config: initialConfig });
  const [open, setOpen] = useState(true);

  const isLastStep = state.currentStep === steps.length;
  const isOfferStep = state.currentStep === steps.length + 1;

  const handleClose = () => {
    setOpen(false);
  };

  const goToPrevStep = () => {
    setState((prevState) => {
      const newStep =
        prevState.currentStep > 0
          ? prevState.currentStep - 1
          : prevState.currentStep;

      // delete element from config state when going to prev step.
      // !(isLastStep) ? delete state.config[steps[state.currentStep].column_name] : noop;

      return {
        currentStep: newStep,
        config: {
          ...prevState.config,
        },
      };
    });
  };

  const goToNextStep = (stepConfig) => {
    try {
      setState((prevState) => {
        // Calculate the next step
        const newStep =
          state.currentStep !== steps.length
            ? state.currentStep + 1
            : state.currentStep;

        // Only skip has_roof_load and roof_wood questions
        let shouldSkipStep = false;
        if (prevState.config["roof_age"] === "im Bau") {
          const nextStepName = steps[newStep]?.column_name;
          const questionsToSkip = ["has_roof_load", "roof_wood"];
          if (questionsToSkip.includes(nextStepName)) {
            shouldSkipStep = true;
          }
        }

        // Special handling for conditional children when skipping has_roof_load
        if (shouldSkipStep && steps[newStep]?.column_name === "has_roof_load") {
          // Automatically include the config value as if "nein" was selected
          return {
            currentStep: newStep + 1,
            config: {
              ...prevState.config,
              [steps[state.currentStep].column_name]: stepConfig,
              has_roof_load: "nein", // Set this so conditional logic works
            },
          };
        }

        return {
          currentStep: shouldSkipStep ? newStep + 1 : newStep,
          config:
            stepConfig !== null
              ? {
                  ...prevState.config,
                  [steps[state.currentStep].column_name]: stepConfig,
                }
              : {
                  ...prevState.config,
                },
        };
      });

      // Scroll to the next visible step
      const nextStepIndex = state.currentStep + 1;
      const nextVisibleStep = steps[nextStepIndex]?.column_name;
      if (nextVisibleStep) {
        const nextStepElement = document.getElementById(nextVisibleStep);
        if (nextStepElement) {
          nextStepElement.scrollIntoView({ behavior: "smooth" });
        }
      }
    } catch (e) {
      console.error("Error in goToNextStep:", e);
    }
  };

  const conditionalMiddleware = (stepConfig, callback = goToNextStep) => {
    // Special handling for "im Bau" case
    if (
      steps[state.currentStep].column_name === "roof_age" &&
      stepConfig === "im Bau"
    ) {
      // Find the has_roof_load step
      const roofLoadStep = steps.find(
        (step) => step.column_name === "has_roof_load"
      );
      if (roofLoadStep) {
        // Get the conditional children that would show on "nein"
        const conditionalChildren = roofLoadStep.hasConditionalChild.filter(
          (child) => child.showOnValue === "nein"
        );

        // Add these children after the current step
        steps.splice(state.currentStep + 1, 0, ...conditionalChildren);
      }
    }
    // Regular conditional logic for other cases
    else if (steps[state.currentStep].hasConditionalChild.length > 0) {
      steps[state.currentStep].hasConditionalChild.forEach((conditional) => {
        if (conditional.showOnValue === stepConfig) {
          // add conditional children
          steps.splice(state.currentStep + 1, 0, conditional);
        } else if (conditional.showOnValue !== stepConfig) {
          // remove ALL conditional children
          steps.splice(state.currentStep + 1);
        }
      });
    }

    callback(stepConfig);
  };

  const validateInputsAndGetStepConfig = () => {
    let isValid = true;
    const stepConfig = {};
    const elements = document.querySelectorAll("input[id^='submit-']");
    const textarea = document.querySelector("#submit-comment-1");

    elements.forEach((input) => {
      const el = input;
      if (!el.validity.valid) {
        isValid = false;
        el.style.borderColor = "#ef4444";
      } else {
        el.style.borderColor = "#d1d5db";
        Object.assign(stepConfig, { [el.name]: el.value });
      }
    });
    Object.assign(stepConfig, { [textarea.name]: textarea.value });
    if (!isValid) {
      return;
    }
    onSubmitCfg(state.config, stepConfig);

    if (processError) {
      setOpen(true);
    }
  };

  const renderChoices = () => {
    // Only skip has_roof_load and roof_wood questions
    const questionsToSkip = ["has_roof_load", "roof_wood"];
    if (
      state.config["roof_age"] === "im Bau" &&
      questionsToSkip.includes(steps[state.currentStep].column_name)
    ) {
      goToNextStep(null);
      return null;
    }

    return (
      <div>
        <div className="mb-small sm:mb-xlarge">
          <div className="p-4 corporate-gradient sm:p-0 sm:bg-none sm:mb-[0.5rem]">
            <p className="heyg-title">{steps[state.currentStep].question}?</p>
          </div>
          <div className="p-4 bg-[#1a1a1a] sm:p-0 sm:bg-transparent">
            <p className="text-4 sm:text-5 text-HEYG-white sm:text-[#1a1a1a]">
              <strong className="inline sm:hidden">Tipp: </strong>
              {steps[state.currentStep].helper}.
            </p>
          </div>
        </div>
        <SectionM>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-2 sm:gap-3.5">
            {steps[state.currentStep].options.map((option) => (
              <HEYGChoiceBox
                key={option.id}
                name={steps[state.currentStep].column_name}
                label={option.label}
                ico={option.ico}
                value={option.value}
                cbOnOptionClick={conditionalMiddleware}
                active={
                  state.config[steps[state.currentStep].column_name] ===
                  option.value
                }
              />
            ))}
          </div>
        </SectionM>
      </div>
    );
  };

  const renderBottomNavigationActions = () => {
    const backButton = () => (
      <button
        type="button"
        className={`${
          state.currentStep > 0 ? "flex" : "invisible"
        } heyg-btn-icon`}
        onClick={goToPrevStep}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="rotate-180"
          viewBox="0 0 24 24"
          height="24"
          width="24"
          fill="#1a1a1a"
        >
          <path d="M17.3711 12.4805C17.7578 12.0938 17.7578 11.4492 17.3711 11.0625L9.03516 2.68359C8.60547 2.29688 7.96094 2.29688 7.57422 2.68359L6.58594 3.67188C6.19922 4.05859 6.19922 4.70312 6.58594 5.13281L13.2031 11.75L6.58594 18.4102C6.19922 18.8398 6.19922 19.4844 6.58594 19.8711L7.57422 20.8594C7.96094 21.2461 8.60547 21.2461 9.03516 20.8594L17.3711 12.4805Z" />
        </svg>
      </button>
    );

    const submitButton = () => (
      <button
        type="button"
        className="heyg-btn-primary"
        onClick={() =>
          setTimeout(() => {
            validateInputsAndGetStepConfig();
          }, 250)
        }
      >
        Absenden »
      </button>
    );

    const renderStepProgress = () => {
      return (
        <span>Schritt {`${state.currentStep + 1}/${steps.length + 2}`}</span>
      );
    };

    return (
      <div className="relative px-8 pb-4 sm:mx-28 mt-20">
        <div className="fixed bottom-0 mt-8 left-0 right-0 py-3 md:py-4 bg-white shadow-md z-40">
          <div className="max-w-[990px] mx-auto px-4 sm:px-6 flex justify-start sm:justify-between items-center">
            <div className="w-1/6 sm:w-1/3">{backButton()}</div>
            <div className="w-2/6 sm:w-1/3 text-center text-[16px]">
              {renderStepProgress()}
            </div>
            <div className="w-3/6 sm:w-1/3 flex justify-end">
              {isOfferStep && submitButton()}
              {!isLastStep && !isOfferStep && <div />}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const offerButton = () => (
    <button
      type="button"
      className="heyg-btn-primary mt-8 lg:!w-96 lg:float-right"
      onClick={() =>
        setTimeout(() => {
          setState({
            currentStep: state.currentStep + 1,
            config: { ...state.config },
          });
        }, 250)
      }
    >
      Angebot einholen »
    </button>
  );

  return (
    <div id={steps[state.currentStep]?.column_name} className="pt-0 sm:pt-10">
      {processError && (
        <HEYGAlertDialog
          title={
            "Leider konnte deine Anfrage nicht erfolgreich übermittelt werden"
          }
          isOpen={open}
          handleClose={handleClose}
        />
      )}
      {isLastStep && (
        <ConfiguratorPrice
          config={state.config}
          roofSize={parseFloat(roofSize)}
          implementationType={implementationType}
          priceCallback={(diy, professional) => {
            state.config.price = { diy, professional };
          }}
          offerButton={isLastStep && offerButton()}
        />
      )}
      {isOfferStep && <ConfiguratorSubmit currentConfig={state.config} />}
      {!isLastStep && !isOfferStep && renderChoices()}
      <HEYGHelpTooltip />
      {renderBottomNavigationActions()}
    </div>
  );
}

ConfiguratorChoices.propTypes = {
  processError: PropTypes.bool,
  onSubmitCfg: PropTypes.func.isRequired,
  steps: PropTypes.array.isRequired,
  roofSize: PropTypes.number.isRequired,
  implementationType: PropTypes.string.isRequired,
};

ConfiguratorChoices.defaultProps = {
  processError: false,
};

export default ConfiguratorChoices;
